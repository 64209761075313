import Testimonials from "./components/Testimonials.js";
function App() {
  return (
    <div className="main-container">
      <Testimonials />
    </div>
  );
}

export default App;
